<!-- footer with contact, copyright and technology -->
<footer>
  <!-- three boxes with technology and support info, contact and social media / code -->
  <div class="panel">
    <div class="box about">
      <p class="mat-body">
        DSP-APP is built with
        <a href="https://angular.io" target="_blank">Angular</a>, styled with
        <a href="https://material.angular.io" target="_blank">Material</a>
        &middot;
        <a href="https://github.com/dasch-swiss/dsp-api/" target="_blank">DSP-API</a>
        and
        <a href="https://sipi.io" target="_blank">Sipi</a> under the hood
        <br />
        <br />
        Supported by
        <a href="http://sagw.ch" target="_blank">Swiss Academy of Humanities and Social Sciences</a>,
        <a href="http://swissuniversities.ch" target="_blank">Swissuniversities</a>
        and
        <a href="http://snf.ch" target="_blank">Swiss National Science Foundation</a>.
      </p>
    </div>
    <div class="box contact">
      <a mat-button href="https://dasch.swiss" target="_blank"
        >Swiss National Data and Service Center for the Humanities</a
      >
      <a mat-button href="https://www.google.com/maps/place/Gewerbestrasse+24,+4123+Allschwil" target="_blank">
        <mat-icon>location_on</mat-icon> Gewerbestrasse 24, 4123 Allschwil
      </a>
      <a mat-button href="mailto:info@dasch.swiss?subject=Get information on the DSP application">
        <mat-icon>email</mat-icon> info&#64;dasch.swiss
      </a>
      <a mat-button href="tel:+41-61-207-64-00"> <mat-icon>phone</mat-icon> +41 61 207 64 00 </a>
    </div>
    <div class="box social-media">
      <a mat-button href="https://www.github.com/dasch-swiss" target="_blank">
        <mat-icon>
          <svg role="img" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M 9 0 C 4.03125 0 0 4.03125 0 9 C 0 12.976562 2.578125 16.351562 6.15625 17.539062 C 6.605469 17.625 6.75 17.34375 6.75 17.109375 L 6.75 15.433594 C 4.246094 15.976562 3.726562 14.371094 3.726562 14.371094 C 3.316406 13.328125 2.726562 13.054688 2.726562 13.054688 C 1.910156 12.496094 2.789062 12.507812 2.789062 12.507812 C 3.691406 12.570312 4.167969 13.433594 4.167969 13.433594 C 4.96875 14.808594 6.273438 14.410156 6.785156 14.183594 C 6.867188 13.601562 7.097656 13.203125 7.359375 12.980469 C 5.359375 12.75 3.257812 11.976562 3.257812 8.53125 C 3.257812 7.546875 3.609375 6.746094 4.183594 6.113281 C 4.089844 5.886719 3.78125 4.972656 4.273438 3.734375 C 4.273438 3.734375 5.027344 3.492188 6.746094 4.65625 C 7.464844 4.457031 8.234375 4.355469 9 4.351562 C 9.765625 4.355469 10.535156 4.457031 11.253906 4.65625 C 12.972656 3.492188 13.726562 3.734375 13.726562 3.734375 C 14.21875 4.972656 13.910156 5.886719 13.816406 6.113281 C 14.394531 6.746094 14.742188 7.546875 14.742188 8.53125 C 14.742188 11.988281 12.636719 12.75 10.632812 12.972656 C 10.957031 13.25 11.25 13.796875 11.25 14.636719 L 11.25 17.109375 C 11.25 17.347656 11.394531 17.628906 11.851562 17.539062 C 15.425781 16.347656 18 12.976562 18 9 C 18 4.03125 13.96875 0 9 0 Z M 9 0"></path>
          </svg>
        </mat-icon>
        dasch-swiss
      </a>
      <a mat-button href="https://twitter.com/DaSCHSwiss" target="_blank">
        <mat-icon>
          <svg role="img" viewBox="0 0 18 18" width="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M 18 3.417969 C 17.335938 3.710938 16.625 3.910156 15.878906 4 C 16.640625 3.542969 17.226562 2.820312 17.503906 1.957031 C 16.789062 2.378906 16 2.6875 15.15625 2.851562 C 14.484375 2.132812 13.523438 1.6875 12.460938 1.6875 C 10.078125 1.6875 8.324219 3.910156 8.863281 6.21875 C 5.796875 6.066406 3.074219 4.597656 1.253906 2.363281 C 0.285156 4.023438 0.75 6.191406 2.394531 7.292969 C 1.789062 7.273438 1.222656 7.105469 0.722656 6.832031 C 0.683594 8.539062 1.910156 10.140625 3.683594 10.496094 C 3.164062 10.640625 2.597656 10.671875 2.015625 10.5625 C 2.488281 12.027344 3.851562 13.09375 5.46875 13.125 C 3.914062 14.34375 1.960938 14.886719 0 14.65625 C 1.632812 15.703125 3.574219 16.3125 5.660156 16.3125 C 12.515625 16.3125 16.390625 10.523438 16.15625 5.328125 C 16.878906 4.808594 17.503906 4.15625 18 3.417969 Z M 18 3.417969"></path>
          </svg>
        </mat-icon>
        DaSCHSwiss
      </a>
      <a mat-button href="https://facebook.com/dasch.swiss" target="_blank">
        <mat-icon>
          <svg role="img" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M 6.75 6 L 4.5 6 L 4.5 9 L 6.75 9 L 6.75 18 L 10.5 18 L 10.5 9 L 13.230469 9 L 13.5 6 L 10.5 6 L 10.5 4.75 C 10.5 4.035156 10.644531 3.75 11.335938 3.75 L 13.5 3.75 L 13.5 0 L 10.644531 0 C 7.945312 0 6.75 1.1875 6.75 3.460938 Z M 6.75 6 "></path>
          </svg>
        </mat-icon>
        dasch.swiss
      </a>
    </div>
  </div>

  <!-- copyright info -->
  <div class="panel no-padding">
    <p class="mat-caption">
      <a href="https://dasch.swiss" target="_blank">DaSCH</a> &#64;
      <a href="https://unibas.ch" target="_blank">University of Basel</a>
      &copy; 2017 - {{ currentYear.getFullYear() }}
    </p>
  </div>
</footer>
